<template>
  <div class="login">
    <h1>Users</h1>
    <img alt="Vue logo" src="../assets/logo.png" />
  </div>
</template>
<script>
export default {
  computed: {
    localauth() {
      return this.$store.state.authenticated;
    },
  },
  mounted() {
    if (!this.localauth) {
      this.$router.push("/");
    }
  },
};
</script>
